import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import 'firebase/compat/database';
import 'firebase/compat/auth';

// Replace with your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBa5qnCsFQrFotzrAR6dOhjkoAKBI98QlE",
    authDomain: "album090723.firebaseapp.com",
    projectId: "album090723",
    storageBucket: "album090723.appspot.com",
    messagingSenderId: "368247061062",
    appId: "1:368247061062:web:ce93db9e932107d2292847",
    measurementId: "G-86H2WBHTVE",
  databaseURL: "https://album090723-default-rtdb.asia-southeast1.firebasedatabase.app",
};

firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();
const database = firebase.database();


export { firebase, storage, database };