import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ImageUpload from './ImageUpload';
import reportWebVitals from './reportWebVitals';
import Login from './Components/Login';
import { firebase } from './Components/Firebase'; // Make sure you import your Firebase configuration from the right path

const Index = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [databasePin, setDatabasePin] = useState('');
  

  useEffect(() => {
    // Fetch the PIN from the real-time database
    const pinRef = firebase.database().ref('passData/pin');
    pinRef.once('value').then((snapshot) => {
      const fetchedPin = snapshot.val();
      console.log('Fetched PIN from Firebase:', fetchedPin); // Log the fetched PIN
      if (fetchedPin === null) {
        // If the PIN is not present in the database, set a default PIN
        const defaultPin = "369987"; // Predefined default PIN
        pinRef.set(defaultPin).then(() => {
          console.log('Default PIN created:', defaultPin);
          setDatabasePin(defaultPin);
        }).catch((error) => {
          console.error('Error creating default PIN:', error);
        });
      } else {
        // Use the fetched PIN from the database
        setDatabasePin(fetchedPin);
      }
    }).catch((error) => {
      console.error('Error fetching PIN from the database:', error);
    });
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <React.StrictMode>
      {isLoggedIn ? (
        <>
          <App />
          <ImageUpload />
        </>
      ) : (
        <Login onLogin={handleLogin} correctPin={databasePin} />
      )}
    </React.StrictMode>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));

reportWebVitals();
