import React from "react";
import { Modal, Backdrop, Fade, IconButton } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

function Pin({ pinSize, imgSrc, name, link, onClick }) {
  const [modalOpen, setModalOpen] = React.useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className={`pin ${pinSize}`} onClick={() => onClick(name)}>
        <img src={imgSrc}  alt="img" className="mainPic" />

        <div className="content">
          <h3>{name}</h3>
          <div className="search">
            <a href={link}>
              <img
              
                src="gs://album090723.appspot.com/Wedding/DSC00332.JPG"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className="modal">
            <div className="modal-content">
              <IconButton onClick={closeModal}>
                <ArrowBackIosNew />
              </IconButton>
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <img src={imgSrc}  alt={name} />
              <IconButton onClick={closeModal} >
                < ArrowForwardIos />
              </IconButton>
              <h2>{name}</h2>
              <p>{link}</p>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default Pin;
