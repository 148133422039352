import "./App.css";
import {} from "@mui/material";
import { Modal, Backdrop, Fade, IconButton } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import {
  Add,
  Chat,
  FavoriteRounded,
  Notifications,
  Person,
  QuestionMark,
} from "@mui/icons-material";
import MenuContainer from "./Components/MenuContainer";
import { useEffect, useState } from "react";
import Pin from "./Pin";
import { firebase, storage} from "./Components/Firebase"; 
import Data from "./Components/Data";
import ImageUpload from "./ImageUpload";
import LazyLoad from "react-lazyload";



function App() {
  const [selectedPin, setSelectedPin] = useState(null);
  const [autoSlide, setAutoSlide] = useState(false);
  const [pinsLoaded, setPinsLoaded] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true); // New state variable
  const [showModal, setShowModal] = useState(false);
  const [imageFilenames, setImageFilenames] = useState([]);
  const [dataWithImageUrls, setDataWithImageUrls] = useState([]);
  const [selectedPinIndex, setSelectedPinIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]); 
  const [enteredPin, setEnteredPin] = useState("");
  const correctPin = "976440"; // Predefined correct PIN
  const [loadedImageCount, setLoadedImageCount] = useState(20); // Number of images initially loaded
  const imagesPerPage = 20; // Number of images to load each time
  const [touchStartX, setTouchStartX] = useState(0);

  const storagePath = "https://storage.googleapis.com/album090723.appspot.com/newgroom";

  const checkPin = (enteredPin) => {
    const correctPin = "976440";
    return enteredPin === correctPin;
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (documentHeight - (scrollTop + windowHeight) < 100) {
      // User has scrolled to the bottom, load more images
      setLoadedImageCount((prevCount) => prevCount + imagesPerPage);
    }
  };

  useEffect(() => {
    const handleTouchStart = (event) => {
      setTouchStartX(event.touches[0].clientX);
    };
  
    const handleTouchEnd = (event) => {
      const touchEndX = event.changedTouches[0].clientX;
      const deltaX = touchEndX - touchStartX;
  
      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          // Swipe right
          handlePrevPin();
        } else {
          // Swipe left
          handleNextPin();
        }
      }
    };
  
    // Add touch event listeners
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
  
    // Remove touch event listeners when component unmounts
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [touchStartX]);
  
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        handlePrevPin();
      } else if (event.key === "ArrowRight") {
        handleNextPin();
      }
    };

    // Add event listener for keyboard arrow keys
    window.addEventListener("keydown", handleKeyDown);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    async function fetchImageUrls() {
      try {
        const storageRef = storage.ref("newgroom");
        const items = await storageRef.listAll();

        const urls = await Promise.all(items.items.map(async (item) => {
          return await item.getDownloadURL();
        }));

        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    }

    fetchImageUrls();
  }, []);
  useEffect(() => {
    async function fetchImageFilenames() {
      try {
        const storageRef = storage.refFromURL("https://storage.googleapis.com/album090723.appspot.com/newgroom");
        const listResult = await storageRef.listAll();
        const filenames = listResult.items.map((item) => item.name);
        setImageFilenames(filenames);
      } catch (error) {
        console.error("Error fetching image filenames:", error);
      }
    }

    fetchImageFilenames();
  }, []);

  useEffect(() => {

    // Fetch the image URLs from Firebase Storage when the component mounts
    async function fetchDataWithImageUrls() {
      try {
        const imageUrlsArray = [];
        // Get a list of all items (images) in the "newgroom" folder in Firebase Storage
        const storageRef = storage.ref("newgroom");
        const items = await storageRef.listAll();

        // Generate the Data array entries based on the image filenames
        const dataEntries = items.items.map((item, index) => ({
          id: index + 1,
          imgSrc: item.name,
          size: "small", // You can customize this as needed
        }));

        // Fetch image URLs and update the state
        for (const item of dataWithImageUrls) { // Use dataWithImageUrls instead of Data
          try {
            // Use getDownloadURL() to get the public HTTP URL for the image
            const imageUrl = await storage.refFromURL(item.imgSrc).getDownloadURL();
            imageUrlsArray.push({ ...item, imgSrc: imageUrl });
          } catch (error) {
            console.error("Error fetching image URL:", error);
            // Handle error (e.g., set a default image URL)
            imageUrlsArray.push({ ...item, imgSrc: "DEFAULT_IMAGE_URL" });
          }
        }
        setDataWithImageUrls(imageUrlsArray);
      } catch (error) {
        console.error("Error fetching data with image URLs:", error);
      }
    }

    fetchDataWithImageUrls();
  }, []);



  useEffect(() => {
    const allIcon = document.querySelectorAll(".iconContainer");

    function setMenuActive() {
      allIcon.forEach((n) => n.classList.remove("black"));
      this.classList.add("black");
    }

    allIcon.forEach((n) => n.addEventListener("click", setMenuActive));
  }, []);

  // Initialize selectedPin with the first pin from Data array
  useEffect(() => {
    setSelectedPin(Data[0]);
    setPinsLoaded(true);
  }, []);

  useEffect(() => {
    if (autoSlide) {
      // Start the automatic slide show at an interval of 5 seconds
      const interval = setInterval(() => {
        setSelectedPin((prevSelectedPin) => {
          const currentIndex = Data.findIndex((pin) => pin.id === prevSelectedPin.id);
          if (currentIndex < Data.length - 1) {
            return Data[currentIndex + 1];
          } else {
            return Data[0];
          }
        });
      }, 5000);

      // Clear the interval when the component is unmounted
      return () => clearInterval(interval);
    }
  }, [autoSlide]);

  useEffect(() => {
    if (pinsLoaded && initialLoad) {
      setInitialLoad(false);
    }
  }, [pinsLoaded, initialLoad]);


  const openModal = (pin, index) => {
    setSelectedPin(pin);
    setSelectedPinIndex(index); 
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedPin(null);
    setShowModal(false);
    setAutoSlide(false); 
  };

  const handlePrevPin = () => {
    setSelectedPinIndex((prevIndex) => {
      const newIndex = (prevIndex - 1 + imageFilenames.length) % imageFilenames.length;
      return newIndex;
    });
  };
  
  const handleNextPin = () => {
    setSelectedPinIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % imageFilenames.length;
      return newIndex;
    });
  };
  

  const handleAutoSlideToggle = () => {
    setAutoSlide((prevAutoSlide) => !prevAutoSlide);
  };
  useEffect(() => {
    let intervalId;

    if (autoSlide) {
      intervalId = setInterval(() => {
        handleNextPin();
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSlide]);

  // After the initial pins are loaded, set initialLoad to false
  useEffect(() => {
    if (pinsLoaded && initialLoad) {
      setInitialLoad(false);
    }
  }, [pinsLoaded, initialLoad]);

  return (
    <>
      <div className="header">
        <div className="header_landing">
          <h1> Engagnment Day </h1>
          <h1> 09.07.2023 </h1>
        </div>
      </div>
      <div className="App">
        <div className={`menuContainer ${window.innerWidth <= 768 ? 'hideOnMobile' : ''}`}>
          <img
            src="gs://album090723.appspot.com"
            alt=""
            className="logo"
          />

          <div className="subMenu">
            <h1>Niraj & Chetna</h1>
          </div>
        </div>

        <main style={{ marginLeft: window.innerWidth <= 768 ? '0' : '130px' }}>
          <div>
            {/* sort code here*/}
          </div>
          
          {/* Only render the slider after the initial load has occurred */}
          {initialLoad ? null : (
          <div className="mainContainer">
            {imageFilenames.slice(0, loadedImageCount).map((filename, index) => (
                <Pin
                  onClick={() => openModal(filename, index)}
                  key={index}
                  pinSize="medium" // Customize as needed
                  imgSrc={`${storagePath}/${filename}`}
                  name={filename}
                />
            ))}
          </div>
        )}

          <Modal
            open={showModal}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={selectedPin !== null}>
              <div className="modal">
                <div className="modal-content">
                    <div className="autoSlideToggle" >
                        <IconButton onClick={handlePrevPin}>
                          <ArrowBackIosNew
                            style={{ fontSize: "30px", padding: "10px" }}
                            className="Button_fb"
                          />
                        </IconButton>
                          
                          <button
                            className="Slider_btn"
                            type="button"
                            id="autoSlideCheckbox"
                            checked={autoSlide}
                            onClick={handleAutoSlideToggle}
                            style={{ backgroundColor: autoSlide ? "green" : "red" }}
                          >
                            Auto Slide
                          </button>
                          <IconButton onClick={handleNextPin}>
                          <ArrowForwardIos
                            style={{ fontSize: "30px", padding: "10px" }}
                            className="Button_fb"
                          />
                        </IconButton>
                  </div>
                  
                  <span className="close" onClick={closeModal}>
                    &times;
                  </span>
                  <img
                    src={imageUrls[selectedPinIndex % imageFilenames.length]}
                    className="modal-image"
                    //style={{ width: "auto", height: "95vh" }}
                    alt={`Image ${selectedPinIndex}`}
                  />
                  <h2>{selectedPin?.name}</h2>
                  <p>{selectedPin?.link}</p>
                </div>
              </div>
            </Fade>
          </Modal>
        </main>
      </div>
    </>
  );
}

export default App;
