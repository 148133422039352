import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import '../src/index.css'

const ImageUpload = ({ checkPin }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null); // New state for upload status

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
    setUploadStatus(null); // Reset upload status when files are selected
  };

  const handleUpload = async () => {
    const enteredPin = prompt("Please enter the PIN:");
  
    if (enteredPin === "976440") { // Replace with your predefined PIN
      if (selectedFiles.length === 0) {
        alert("Please select one or more files.");
        return;
      }
  
      const storageRef = firebase.storage().ref();
  
      const uploadPromises = selectedFiles.map(async (file) => {
        const reader = new FileReader();
  
        return new Promise((resolve, reject) => {
          reader.onload = async (event) => {
            const image = new Image();
            image.src = event.target.result;
  
            image.onload = async () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");
  
              const maxWidth = 800;
              const maxHeight = 800;
  
              let width = image.width;
              let height = image.height;
  
              if (width > height) {
                if (width > maxWidth) {
                  height *= maxWidth / width;
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width *= maxHeight / height;
                  height = maxHeight;
                }
              }
  
              canvas.width = width;
              canvas.height = height;
  
              ctx.drawImage(image, 0, 0, width, height);
  
              canvas.toBlob(async (blob) => {
                const uploadTask = storageRef.child("newgroom/" + file.name).put(blob);
  
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setProgress(progress);
                  },
                  (error) => {
                    reject(error);
                  },
                  () => {
                    resolve(file.name); // Resolve with the uploaded file name
                  }
                );
              }, "image/jpeg", 0.9);
            };
          };
  
          reader.readAsDataURL(file);
        });
      });
  
      Promise.all(uploadPromises)
        .then((uploadedFileNames) => {
          setUploadStatus(`Uploaded ${uploadedFileNames.join(", ")} successfully.`);
          setProgress(0); // Reset progress
  
          // Clear the success message after 3 seconds
          setTimeout(() => {
            setUploadStatus(null);
          }, 10000);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setUploadStatus("An error occurred during upload.");
          setProgress(0); // Reset progress
        });
    } else {
      alert("You are not an authorized person.");
    }
  };
  

  return (
    <>
    <div className="footer_b">
    <div className="footer">
    <button onClick={handleUpload}>Upload</button>
      <input className="custom-file-input" type="file" onChange={handleFileChange} multiple />
    </div>
    <div>
      <progress value={progress} max="100" />
      {uploadStatus && <p className="upload-status">{uploadStatus}</p>}
    </div>
    </div>
    </>
  );
};

export default ImageUpload;
