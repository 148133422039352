import React, { useState, useEffect } from "react";
import '../Components/Login.css'
import Video_1 from '../img/video.mp4'
import { firebase } from './Firebase'; // Make sure to import the Firebase configuration from the correct path

const Login = ({ onLogin }) => {
  const [enteredPin, setEnteredPin] = useState("");
  const [correctPin, setCorrectPin] = useState(""); // State to store the fetched correct PIN

  // Fetch the correct PIN from Firebase
  useEffect(() => {
    const pinRef = firebase.database().ref('passData/pin');
    pinRef.once('value').then((snapshot) => {
      const fetchedPin = snapshot.val();
      setCorrectPin(fetchedPin);
    }).catch((error) => {
      console.error('Error fetching PIN from the database:', error);
    });
  }, []);

  const handleLogin = (event) => {
    event.preventDefault();
    if (correctPin !== "" && checkPin(enteredPin, correctPin)) {
      onLogin();
    } else {
      alert("Incorrect PIN");
    }
  };

  const checkPin = (enteredPin, correctPin) => {
    return enteredPin === correctPin;
  };

  return (
    <div className="login-container">
    <video
        autoPlay
        muted
        loop
        className="login-background-video" // Apply the background video styles
    >
        <source src={Video_1} type="video/mp4" />
        {/* You can add more <source> elements for different video formats */}
        Your browser does not support the video tag.
      </video>
      <div className='box1'>
      <h2 style={{padding:'5px'}}>PIN</h2>
      <form onSubmit={handleLogin}>
        <input
          type="password"  // Use password input for PIN entry
          value={enteredPin}
          placeholder="Ask pin to Niraj or Chetna"
          onChange={(e) => setEnteredPin(e.target.value)}
          className='box2'
        />
        <button className="box3" type="submit">Login</button>
      </form>
      </div>
    </div>
  );
};

export default Login;
